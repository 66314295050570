import { useRef, useEffect, useState } from 'react';
import { PlayCircleFill } from 'react-bootstrap-icons';
import { ReactComponent as MuteCircleFill } from '../assets/mute-circle-fill.svg';

import { gsap } from 'gsap';

function Video() {
    const videoRef = useRef(null);

    const [hoverVideo, setHoverVideo] = useState(false);
    const [playVideo, setPlayVideo] = useState(false);

    function hideBlur(videoDuration) {
        gsap.to('video', {
            filter: 'blur(0px)',
            transform: 'scale(1.0)',
            duration: videoDuration,
            ease: "back.inOut"
        });
    }

    useEffect(() => {
        const playButtonDuration = 0.3;
        const videoDuration = 0.2;

        if (hoverVideo) {
            gsap.to('.action-button', {
                scale: 1,
                duration: playButtonDuration,
                ease: "back.inOut"
            });

            gsap.to('video', {
                filter: 'blur(2px)',
                transform: 'scale(1.05)',
                duration: videoDuration,
                ease: "back.inOut"
            });
        } else {
            gsap.to('.action-button', {
                scale: 0,
                duration: playButtonDuration,
                ease: "back.inOut"
            });

            hideBlur(videoDuration);
        }

        if (playVideo) {
            hideBlur(videoDuration);
        }
    }, [hoverVideo, playVideo]);

    useEffect(() => {
        const handleMouseMove = (event) => {
            if (videoRef.current) {
                const rect = videoRef.current.getBoundingClientRect();
                const isInside =
                    event.clientX >= rect.left &&
                    event.clientX <= rect.right &&
                    event.clientY >= rect.top &&
                    event.clientY <= rect.bottom;

                setHoverVideo(isInside);
            }
        };

        if (playVideo) {
            videoRef.current.currentTime = 0;
            window.addEventListener('mousemove', handleMouseMove);
        } else {
            // setHoverVideo(false);
            window.removeEventListener('mousemove', handleMouseMove);
        }

        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
        };
    }, [playVideo]);

    return (
        <div className='video' onMouseOver={() => setHoverVideo(true)} onMouseOut={() => setHoverVideo(false)} onClick={() => setPlayVideo(!playVideo)}>
            <video autoPlay muted={!playVideo} loop ref={videoRef} controls={false}>
                <source src='/videos/ubives-explainer-video.mp4' type="video/mp4" />
            </video>
            <div className='action-button'>
                {playVideo ? <MuteCircleFill className='action-button' /> : <PlayCircleFill className='action-button' />} 
            </div>
        </div>
    );
}

export default Video;