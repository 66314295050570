// src/components/ArticleCard.js
import React from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';

const ArticleCard = ({ title, description, publishDate, image, url }) => {
    return (
        <div className="article-card">
            <Helmet>
                <title>{title}</title>
                <meta name="description" content={description} />
                <meta name="publishDate" content={publishDate} />

                {/* Open Graph / Facebook */}
                <meta property="og:type" content="article" />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta property="og:url" content={url} />

                {/* Twitter */}
                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
            </Helmet>

            <div>
                <div className='news-box'>
                    <img src={image} alt='newsimage' className='img-fluid news-img' />
                    <div className='news-desc' onClick={() => window.open(url, '_blank')}>
                        <h3>{title.substring(0, 1).toUpperCase() + title.substring(1)}</h3>
                        {description && <p className='fst-italic'>{description}</p>}
                        <cite>{new Date(publishDate).toLocaleDateString()}</cite>
                    </div>
                </div>
            </div>
        </div>
    );
};

ArticleCard.propTypes = {
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    publishDate: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
};

export default ArticleCard;
